import { Component, OnInit, ViewChild, Inject, Optional } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MatTableDataSource,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppUrl } from "../../common/config/apiurl";
import { SegmentService, UtilService } from "../../common/_service/index";
import { ConfigSettings } from "../../common/config/config";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
export interface Fruit {
  name: string;
}
@Component({
  templateUrl: "./sync-scheduler.component.html",
  styleUrls: ["./sync-scheduler.component.css"],
})
export class SyncSchedulerComponent implements OnInit {
  @ViewChild("successrule", { static: true }) successrule: any;
  @ViewChild("errorrrule", { static: true }) errorrrule: any;
  @ViewChild("emaillist", { static: true }) emaillist: any;
  @ViewChild("rspageslist", { static: true }) rspageslist: any;
  loader: boolean = false;
  op: any = "add";
  reportSchDT: object = [];
  config: any = ConfigSettings;
  success_msg: any = "";
  errorMessage: any = "";
  public roles: any = "";
  selectedValue: any = "";
  repeatfreqweeklysel: any = "";
  showRepeatFreqWeekly = false;
  sDate: Date;
  eDate: Date;
  emaillistdd: any = [];
  
  ELEMENT_DATA: any = [];
  tblDT: any = [];
  tblDTPages: any = [];
  repeatFreqDD: any = {};
  syncreportSchForm: FormGroup;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SyncSchedulerComponent>,
    private _service: SegmentService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private _utilservice: UtilService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.reportSchDT = data ? data : {};
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our email
    if ((value || "").trim()) {
      this.tblDT.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  remove(fruit: Fruit): void {
    const index = this.tblDT.indexOf(fruit);
    if (index >= 0) {
      this.tblDT.splice(index, 1);
    }
  }

  closeMe() {
    this.dialogRef.close();
  }

  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  ngOnInit() {
    this.syncreportSchForm = this.formBuilder.group({      
      repeatfreq: [this.reportSchDT["repeatfreq"], [Validators.required]]      
    });
    this.renderRepeatFreqDD();    
    this.selectedValue = this.reportSchDT["repeatfreq"];
  }



  
  /**
   * @Method : renderRepeatFreqDD
   * @description : render repeat frequency dropdown
   **/
  renderRepeatFreqDD() {
    this.repeatFreqDD = this.config["SYNC_FREQ"];
  }

  
  /**
   * @Method : addReportSceduler
   * @description : Save user detail/roles/permission
   **/
  addReportSceduler() {
    let rdt = {};
    let _userDT = this._service._getUserDt();
    let activeAdvId = this._service.getParamFromLocalStorage("activeAdvId");
    let _url = AppUrl.getAPIURL();
    _url = _url + "/admin/adv/saveReportScheduler";
    let data = this.syncreportSchForm.value;
    let repeatfreqweeklyval =
      data["repeatfreq"] == "weekly" && data["repeatfreqweekly"]
        ? data["repeatfreqweekly"]
        : "";
    rdt = {
      op: this.op,
      adv_id: activeAdvId,
      email_ids: this.tblDT.join(),
      page_ids: this.rspageslist.rspagesLbl.join(),
      sdate: data["sdate"],
      repeatfreq: data["repeatfreq"],
      repeatfreqweekly: repeatfreqweeklyval,
    };
    let isvalid = this.isValidForm(data);
    if (isvalid == false) {
      return;
    }

    this.loader = true;
    rdt["puser_id"] = _userDT["user_id"];
    this._service._postApi(_url, rdt).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config["STATUS_CODE"]["OK"]) {
          this.success_msg = data.msg;
          this.success(this.successrule);
          this.closeMe();
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  
  this_month: boolean = false;
  last_month: boolean = false;
  isValidForm(data: any) {     
    let v =  this.syncreportSchForm.get("repeatfreq").value;    
    if (
      v == "" || typeof(v) === "undefined"
    ) {      
      return false;
    } else {
      return true;
    }      
  }

  addSyncReportSceduler(){
    let data = this.syncreportSchForm.value;
    console.log(data.repeatfreq);
    // validate the data value it is string and should not empty
    let isvalid = this.isValidForm(data);    
    if (isvalid == false) {
      return false;
    }
    else {
      let _userDT = this._service._getUserDt();
      console.log(_userDT);
      let _url = AppUrl.getAPIURL();
      _url = _url + "/insightsync/saveSyncReportScheduler";

      this.loader = true;
      const rdt = {}
      rdt["puser_id"] = _userDT["user_id"];
      rdt["duration"] = data.repeatfreq;
      this._service._postApi(_url, rdt).subscribe(
        (data: any) => {
          this.loader = false;
          if (data.s == this.config["STATUS_CODE"]["OK"]) {
            this.success_msg = data.msg;
            this.success(this.successrule);
            this.closeMe();
          } else {
            this.errorMessage = data.msg;
            this.error(this.errorrrule);
          }
        },
        (error: any) => {
          this.loader = false;
          console.log(error);
        }
      );


    }
    

  
    //console.log("here")
    //console.log(data)
  }

  success(successrule: any) {
    this.activeModal = this.modalService.open(successrule, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }

  error(errortatus: any) {
    this.activeModal = this.modalService.open(errortatus, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }

  closeError() {
    this.activeModal.close();
  }
}

export interface PeriodicElement {
  account: string;
}
